import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { ServersMap } from '@/assets/svg/servers-map';

export default function WorldMap() {
  const { t } = useTranslation('components/servers-world-map');

  return (
    <div className='flex items-center justify-center'>
      <div className='w-full max-w-[1400px] p-5 lg:pb-40'>
        <div className='relative'>
          <div className='relative z-10 overflow-hidden max-tablet:h-auto'>
            <div className='flex flex-col items-center'>
              <h2 className='max-w-[887px] text-8-5xl font-bold leading-none text-white max-tablet:text-5xl xl:text-center'>{t('world_wide')}</h2>
              <p className='max-w-[600px] pb-6 pt-4 font-medium text-[#8A8A9A] xl:text-center'>{t('world_wide_description')}</p>
              <Link href='/servers' className='xl:max-w-auto w-full max-w-full rounded-lg bg-green-300 px-10 py-4 text-center text-sm font-bold uppercase text-black transition-all duration-200 hover:opacity-80 hover:shadow-xl xl:w-auto'>
                {t('button_see_the_servers')}
              </Link>
            </div>
          </div>
          <Link href='/servers' className='mt-24 hidden items-center justify-center xl:flex' aria-label='Veja os servidores'>
            <ServersMap />
          </Link>
        </div>
      </div>
    </div>
  );
}
